* {
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  /* list-style: none; */
  scroll-behavior: smooth;
}

/* body {
  background-color: #e0ebff;
} */
/* h1 {
  font-family: Poppins;
  font-size: 91px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; 
  letter-spacing: -4.55px;
}

h2 {

  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 98.5%;
  letter-spacing: -1.8px;
}

h3 {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

h4 {
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}



h6 {
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

p {
  text-align: center;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
}

a {
  color: #000;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

span {
  color: #95a2a7;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

button {
  color: #000;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
} */

/* .Navbar ul li:not(:last-child){
    margin-right: 55px;
} */

.TextField {
  /* height: 50px;
  margin-bottom: 18px;
  border: 1px none #000;
  border-radius: 6px; */
  transition: box-shadow 350ms ease;
}

.TextField:focus {
  box-shadow: 0px 8px 0px #1767F4;
  border-radius: 11px;
  /* border: 2px solid #000000; */
  /* box-shadow: 0 5px 0 0 #00a3fb; */
  /* background: #FFFFFF 0% 0% no-repeat padding-box; */
}

h5 {
  color: #a5b5cd;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.card {
  backdrop-filter: blur(2px) saturate(145%);
  -webkit-backdrop-filter: blur(2px) saturate(145%);
  background-color: rgba(17, 25, 40, 0.23);
  border: 1px solid rgba(255, 255, 255, 0.125);
  padding: 20px;
}

.firstdoteActive {
  width: 46px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 999px;
  background-color: rgba(217, 217, 217, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondDoteActive {
  width: 24px;
  height: 24px;
  border-radius: 999px;
  flex-shrink: 0;
  background-color: rgba(217, 217, 217, 0.2);
  box-shadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  /* box-shadow: ; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.thirdDoteActive {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  border-radius: 999px;
  background-color: #1092e6;
}

.firstdote {
  width: 34px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 999px;
  background-color: rgba(217, 217, 217, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondDote {
  width:  18px;
  height: 18px;
  border-radius: 999px;
  flex-shrink: 0;
  background-color: rgba(217, 217, 217, 0.2);
  box-shadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  /* box-shadow: ; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.thirdDote {
  width:  10px;
  height: 10px;
  flex-shrink: 0;
  border-radius: 999px;
  background-color: rgba(217, 217, 217, 0.47);
}

.swiper-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-pagination-bullet {
  width:  12px !important;
  height: 12px !important;
  background: #1767F4 !important;
  border: 1px solid #fff;
  border-radius: 50%;
}

.swiper-pagination-bullet-active {
  width: 20px !important;
  height: 20px !important;
}
