.middleContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.input-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
  width: 100%; /* Set your desired width */
}

.custom-input {
  border: none;
  outline: none;
  width: 100%;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  /* padding-left: 26px; */
  /* font-weight: 600; */
  font-size: 16px; /* Adjust the font size for your input text */
  transition: border-color 0.5s; /* Add a smooth transition for the border */
}

.custom-input:focus {
  border-bottom: 1.5px solid #5346bd; /* Change the border color on focus */
}

.otp-input {
  width: 43px;
  padding: 3px;
  color: #000;
  /* font-family: Inter; */
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1.5px solid #e9e9e9;
}

.otp-input:focus {
  border-bottom: 1.5px solid #5346bd;
}

.otp-input::-webkit-outer-spin-button,
.otp-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-input[type="number"] {
  -moz-appearance: textfield;
}
